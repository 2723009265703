var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddNewUserSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetUserData,"change":function (val) { return _vm.$emit('update:is-add-new-user-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"\n        d-flex\n        justify-content-between\n        align-items-center\n        content-sidebar-header\n        px-2\n        py-1\n      "},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Zaproś nowego pracownika ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetUserData.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Nazwisko","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nazwisko","label-for":"lastName"}},[_c('b-form-input',{attrs:{"id":"lastName","autofocus":"","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.userData.lastName),callback:function ($$v) {_vm.$set(_vm.userData, "lastName", $$v)},expression:"userData.lastName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Imię","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Imię","label-for":"firstName"}},[_c('b-form-input',{attrs:{"id":"firstName","autofocus":"","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.userData.firstName),callback:function ($$v) {_vm.$set(_vm.userData, "firstName", $$v)},expression:"userData.firstName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),(_vm.currentUser && _vm.currentUser.roles.includes('ROLE_ADMIN'))?[_c('validation-provider',{attrs:{"name":"Poziom dostępu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Poziom dostępu","label-for":"userRole","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"options":_vm.roleOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"userRole"},model:{value:(_vm.userData.role),callback:function ($$v) {_vm.$set(_vm.userData, "role", $$v)},expression:"userData.role"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})]:_vm._e(),(
            _vm.userData.role === 'ROLE_MANAGER' ||
              _vm.userData.role === 'ROLE_EMPLOYEE' ||
              (_vm.currentUser && _vm.currentUser.roles.includes('ROLE_MANAGER'))
          )?[_c('validation-provider',{attrs:{"name":"Dealer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Dealer","label-for":"dealer","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"options":_vm.dealerOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"dealer","disabled":!_vm.isAdmin},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('strong',[_vm._v(_vm._s(option.number))]),_vm._v(" "+_vm._s(option.name)+" ")]}}],null,true),model:{value:(_vm.userData.dealer),callback:function ($$v) {_vm.$set(_vm.userData, "dealer", $$v)},expression:"userData.dealer"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Dział","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Dział","label-for":"departments","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"options":_vm.departmentOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"departments","multiple":""},model:{value:(_vm.userData.departments),callback:function ($$v) {_vm.$set(_vm.userData, "departments", $$v)},expression:"userData.departments"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})]:_vm._e(),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":invalid || _vm.loading}},[_vm._v(" Zaproś ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Anuluj ")])],1)],2)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }