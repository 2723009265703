export default function () {
  if (!this.currentUser) return this.tableColumns.filter(column => !column.visibleTo)

  return this.tableColumns.filter(column => {
    if (column.visibleTo) {
      let found = false
      column.visibleTo.forEach(requiredRole => {
        this.currentUser.roles.forEach(currentRole => {
          if (requiredRole === currentRole) found = true
        })
      })
      return found
    }
    return true
  })
}
