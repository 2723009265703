<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetUserData"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          Zaproś nowego pracownika
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetUserData"
        >
          <!-- Nazwisko -->
          <validation-provider
            #default="{errors}"
            name="Nazwisko"
            rules="required"
          >
            <b-form-group
              label="Nazwisko"
              label-for="lastName"
            >
              <b-form-input
                id="lastName"
                v-model="userData.lastName"
                autofocus
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Imię -->
          <validation-provider
            #default="{errors}"
            name="Imię"
            rules="required"
          >
            <b-form-group
              label="Imię"
              label-for="firstName"
            >
              <b-form-input
                id="firstName"
                v-model="userData.firstName"
                autofocus
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="{errors}"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <template
            v-if="currentUser && currentUser.roles.includes('ROLE_ADMIN')"
          >
            <!-- Poziom dostępu -->
            <validation-provider
              #default="{errors}"
              name="Poziom dostępu"
              rules="required"
            >
              <b-form-group
                label="Poziom dostępu"
                label-for="userRole"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="userData.role"
                  :options="roleOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="userRole"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false:null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>

          <template
            v-if="
              userData.role === 'ROLE_MANAGER' ||
                userData.role === 'ROLE_EMPLOYEE' ||
                (currentUser && currentUser.roles.includes('ROLE_MANAGER'))
            "
          >
            <!-- Dealer -->
            <validation-provider
              #default="{errors}"
              name="Dealer"
              rules="required"
            >
              <b-form-group
                label="Dealer"
                label-for="dealer"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="userData.dealer"
                  :options="dealerOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="dealer"
                  :disabled="!isAdmin"
                >
                  <template #option="option">
                    <strong>{{ option.number }}</strong>&nbsp;{{ option.name }}
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false:null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Dział -->
            <validation-provider
              #default="{errors}"
              name="Dział"
              rules="required"
            >
              <b-form-group
                label="Dział"
                label-for="departments"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="userData.departments"
                  :options="departmentOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="departments"
                  multiple
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false:null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || loading"
            >
              Zaproś
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Anuluj
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    dealerOptions: {
      type: Array,
      required: true,
    },
    departmentOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      loading: false,
      blankUserData: {
        lastName: '',
        firstName: '',
        email: '',
        role: 'ROLE_EMPLOYEE',
        dealer: null,
        departments: [],
      },
      userData: null,
    }
  },

  computed: {

  },

  beforeMount() {
    this.resetUserData()
  },

  methods: {
    assignDealerForManager() {
      // eslint-disable-next-line prefer-destructuring
      this.userData.dealer = this.dealerOptions[0]
    },

    onSubmit() {
      const payload = { ...this.userData }

      function getError(error) {
        switch (error) {
          case 'email: This value is already used.':
            return 'Ten mail jest już używany'
          default:
            return error
        }
      }

      this.loading = true
      store
        .dispatch('user/addUser', payload)
        .then(response => {
          if (response && response.status === 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Zaproszenie wysłane',
                icon: 'UserPlusIcon',
                variant: 'success',
              },
            })
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Nie udało się wysłać zaproszenia.',
                text: response ? getError(response.data['hydra:description']) : null,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    resetUserData() {
      this.userData = JSON.parse(JSON.stringify(this.blankUserData))
      if (!this.isAdmin) { this.assignDealerForManager() }
      if (this.$refs.refFormObserver) { this.$refs.refFormObserver.reset() }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
