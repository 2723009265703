<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :dealer-options="dealerOptions"
      :department-options="departmentOptions"
      @refetch-data="fetchData"
    />

    <!-- Filters -->
    <users-list-filters
      :dealer-filter.sync="dealerFilter"
      :department-filter.sync="departmentFilter"
      :status-filter.sync="statusFilter"
      :role-filter.sync="roleFilter"
      :dealer-options="dealerOptions"
      :department-options="departmentOptions"
      :status-options="statusOptions"
      :role-options="roleOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Pokaż</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>pracowników</label>

            <b-button
              v-if="['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_FORD_POLAND'].some(r => roles.includes(r))"
              variant="gradient-primary"
              class="ml-2"
              @click="exportXls()"
            >
              <span class="text-nowrap">Eksport szkoleń</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Szukaj"
              />
              <b-button
                v-if="isAdmin || isManager"
                variant="gradient-primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Zaproś pracownika</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="usersList"
        responsive
        :fields="visibleTableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nie znaleziono pracowników spełniających podane kryteria."
        :sort-desc.sync="isSortDirDesc"
        :busy="loading"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template
          v-if="!usersList.length"
          #table-busy
        >
          <div class="text-center">
            <b-spinner class="align-middle mr-1" /> Pobieranie listy pracowników
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(roles)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoles(data.item.roles).icon"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoles(data.item.roles)}`"
            />
            <span class="align-text-top text-capitalize">{{
              resolveUserRoles(data.item.roles).text
            }}</span>
          </div>
        </template>

        <!-- Column: Dealer -->
        <template #cell(dealer)="{ item }">
          {{ item.dealer ? item.dealer.name : " - " }}
        </template>

        <!-- Column: Department -->
        <template #cell(department)="{ item }">
          <b-badge
            v-for="department in item.departments"
            :key="department"
            pill
            variant="light-primary"
          >
            {{ resolveDepartment(department) }}
          </b-badge>
          {{ !item.departments || !item.departments.length ? " - " : null }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatus(data.item.status).variant}`"
            class="text-capitalize"
          >
            {{ resolveUserStatus(data.item.status).text.toUpperCase() }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div style="white-space: nowrap">
            <b-button-group>
              <b-button
                size="sm"
                variant="light"
                :to="'/users/edit/' + data.item.id"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edytuj</span>
              </b-button>

              <b-button
                v-if="isAdmin"
                size="sm"
                variant="light"
                @click="userToDelete = data.item"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">Usuń</span>
              </b-button>
            </b-button-group>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            >Widok {{ metadata.from }} - {{ metadata.to }} z
              {{ metadata.of }} pracowników</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      v-if="userToDelete"
      v-model="deleteModalOpen"
      centered
      modal-class="modal-danger"
      title="Potwierdź usunięcie"
      ok-variant="danger"
      cancel-variant="light"
      ok-title="Usuń"
      cancel-title="Anuluj"
      @ok="deleteUser"
    >
      Na pewno usunąć pracownika
      <strong>{{ userToDelete.firstName }} {{ userToDelete.lastName }} </strong>?</b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BPagination, BButtonGroup, BModal, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import departmentOptions from '@/helpers/departmentOptions'
import roleOptions from '@/helpers/roleOptions'
import resolveUserRoles from '@/helpers/resolveUserRoles'
import resolveUserStatus from '@/helpers/resolveUserStatus'
import resolveDepartment from '@/helpers/resolveDepartment'
import visibleTableColumns from '@/helpers/visibleTableColumns'
import UsersListFilters from './UsersListFilters.vue'
import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BButtonGroup,
    BModal,
    BSpinner,

    vSelect,
  },

  setup() {
    const isAddNewUserSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Aktywny', value: 'ACTIVE' },
      { label: 'Zaproszony', value: 'INVITED' },
      { label: 'Nieaktywny', value: 'INACTIVE' },
    ]

    const tableColumns = [
      { key: 'lastName', sortable: true, label: 'Nazwisko' },
      { key: 'firstName', label: 'Imię' },
      { key: 'email', label: 'Email' },
      {
        key: 'roles', sortable: true, label: 'Poziom dostępu', visibleTo: ['ROLE_ADMIN', 'ROLE_FORD_POLAND'],
      },
      { key: 'dealer', sortable: true, label: 'Dealer' },
      { key: 'department', label: 'Dział' },
      { key: 'status', sortable: true, label: 'Status konta' },
      { key: 'actions', label: 'Akcje', visibleTo: ['ROLE_ADMIN', 'ROLE_MANAGER'] },
    ]

    // Table Handlers
    const perPage = ref(10)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('lastName')
    const isSortDirDesc = ref(true)
    const dealerFilter = ref(null)
    const departmentFilter = ref(null)
    const statusFilter = ref(null)
    const roleFilter = ref(null)

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      // Filter
      statusOptions,
      roleOptions,

      // Extra Filters
      dealerFilter,
      departmentFilter,
      statusFilter,
      roleFilter,
    }
  },
  data() {
    return {
      userToDelete: null,
      loading: false,
      departmentOptions,
    }
  },
  computed: {

    usersList() {
      let list = this.$store.state.user.list ? this.$store.state.user.list['hydra:member'] : []

      if (this.searchQuery) {
        const explodedQuery = this.searchQuery.toLowerCase().split(' ')
        let found = false

        list = list.filter(item => {
          found = false
          Object.values(item).forEach(value => {
            explodedQuery.forEach(query => {
              if (value && value.toString().toLowerCase().indexOf(query) !== -1) found = true
            })
          })
          return found
        })
      }

      if (this.statusFilter) {
        list = list.filter(item => item.status === this.statusFilter)
      }

      if (this.roleFilter) {
        list = list.filter(item => item.roles.indexOf(this.roleFilter) !== -1)
      }

      if (this.dealerFilter) {
        list = list.filter(item => (item.dealer ? item.dealer['@id'] === this.dealerFilter : false))
      }

      if (this.departmentFilter) {
        list = list.filter(item => (item.departments.includes(this.departmentFilter)))
      }

      return list
    },

    dealerList() {
      if (this.isAdmin || this.isFordPoland) {
        return this.$store.state.dealer.list ? this.$store.state.dealer.list['hydra:member'] : []
      }
      if (this.isManager) {
        return [this.currentUser.dealer]
      }
      return []
    },

    dealerOptions() {
      const options = []

      this.dealerList.forEach(dealer => {
        options.push({
          name: dealer.name,
          number: dealer.dealerNo,
          label: (`${dealer.dealerNo} ${dealer.name}`),
          value: dealer['@id'],
        })
      })

      return options
    },

    visibleTableColumns,

    totalUsers() {
      return this.usersList.length
    },

    metadata() {
      const localItemsCount = this.$refs.refUserListTable ? this.$refs.refUserListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      }
    },

    deleteModalOpen: {
      get() {
        return !!this.userToDelete
      },
      set(value) {
        this.userToDelete = value
      },
    },

    userData() {
      return this.$store.state.auth.userData ? this.$store.state.auth.userData : {}
    },

    roles() {
      return this.userData && this.userData.roles ? this.userData.roles : []
    },
  },
  mounted() {
    if (this.isManager) {
      this.setManagerFilters()
    }

    this.fetchData()
  },
  methods: {
    resolveUserRoles,
    resolveUserStatus,
    resolveDepartment,
    fetchData() {
      this.loading = true
      Promise.all([
        this.$store.dispatch('user/fetchList', {
          params: {
            roles: this.isManager ? 'ROLE_EMPLOYEE' : undefined,
          },
        }),
        ((this.isAdmin || this.isFordPoland) ? this.$store.dispatch('dealer/fetchList') : null),
      ]).finally(() => { this.loading = false })
    },
    deleteUser() {
      this.$store.dispatch('user/deleteUser', { id: this.userToDelete.id }).then(response => {
        if (response && response.status === 204) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pracownik został usunięty',
              icon: 'UserXIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Nie udało się usunąć pracownika.',
              text: response ? response.data['hydra:description'] : null,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
            timeout: false,
          })
        }
        this.$store.dispatch('user/fetchList')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nie udało się usunąć pracownika.',
            text: error ? error.data['hydra:description'] : null,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
          timeout: false,
        })
      })
    },

    setManagerFilters() {
      this.departmentOptions = this.departmentOptions.filter(option => this.currentUser.departments.includes(option.value))

      this.dealerFilter = this.dealerOptions[0].value
    },

    async exportXls() {
      const params = {
        'user.dealer': this.dealerFilter,
        'trainingCourse.department': this.departmentFilter,
        'user.status': this.statusFilter,
        'user.roles': this.roleFilter,
      }
      const response = await this.$http.get('/enrolments/export', { params, responseType: 'blob' })
      if (response.status === 200) {
        const blob = new Blob([response.data, 'application/vnd.ms-excel'])
        this.downloadBlob(blob, 'szkolenia.xls')
      }
    },
    downloadBlob(blob, name = 'file.txt') {
      if (
        window.navigator
      && window.navigator.msSaveOrOpenBlob
      ) return window.navigator.msSaveOrOpenBlob(blob)

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = data
      link.download = name

      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      )

      setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
        link.remove()
      }, 100)
      return 1
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
